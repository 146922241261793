<template>
  <div class="toolbar">
    <div class="dropdown">
      <button @click="toggleDropdown('file')">File</button>
      <div class="dropdown-content" v-bind:style="{ display: currentDropdown === 'file' ? 'block' : 'none' }">
        <a href="#">New</a>
        <a href="#">Save</a>
        <a href="#">Open</a>
      </div>
    </div>
    <div class="dropdown">
      <button @click="toggleDropdown('edit')">Edit</button>
      <div class="dropdown-content edit" v-bind:style="{ display: currentDropdown === 'edit' ? 'block' : 'none' }">
        <a @click="addPlatform">New Platform</a>
      </div>
    </div>
  </div>
  <div class="flex-container">
    <div class="canvas-container">
      <div class="canvas" ref="canvas">
        <img v-if="platformImage" :src="platformImage" alt="Platform" ref="platform" />
      </div>
    </div>
    <div class="sidebar">
      <h1>Name: </h1>
    </div>
  </div> 
</template>

<script>
import platformImage from '../assets/Circle.png'; 

export default {
  data() {
    return {
      currentDropdown: '',
      platformImage: '',
      platforms: [], 
    }
  },
  methods: {
    toggleDropdown(dropdownName) {
      if (this.currentDropdown === dropdownName) {
        this.currentDropdown = '';
      } else {
        this.currentDropdown = dropdownName;
      }
    },
    addPlatform() {
  this.currentDropdown = '';
  this.platformImage = platformImage;
  this.platforms.push({ 
    width: 1, 
    height: 1,
    x: 50,
    y: 100 ,
    material: 'default' 
  }); 
  this.$nextTick(() => {
    this.updatePlatform();
  });
},
updatePlatform() {
  if (this.$refs.canvas && this.$refs.platform) {
    let canvasWidth = this.$refs.canvas.getBoundingClientRect().width * 0.1;
    this.platforms = this.platforms.map(platform => {
      let platformWidth = platform.width;
      let platformHeight = platform.height;

      //scale proportionately to the canvas to keep size consitent between game and editor
      this.$refs.platform.style.width = `${platformWidth*canvasWidth}px`;
      this.$refs.platform.style.height = `${platformHeight*canvasWidth}px`;

      return platform;
    });
  }
},
optionClicked() {
  this.currentDropdown = '';
}
  },
  mounted() {
    window.addEventListener('resize', this.updatePlatform);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updatePlatform);
  }
}
</script>