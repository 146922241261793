<template>
    <nav class="NavBar">
        <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/editor">Editor</a></li>
        </ul>
    </nav>
</template>

<script>
export default {
    name: 'NavBar',
}
</script>