<template>
    <NavBar />
    <div class="home">
        <h1>Bopl Battle Map editor</h1>
        <p></p>
    </div>
</template>

<script>
import NavBar from '../components/NavBar.vue'

export default {
    name: 'HomePage',
    components: {
        NavBar
    }
}
</script>
